@import 'variables';

$prefix: $antPrefix + -btn;
$notClasses: '&:not(.#{$prefix}-primary):not(.#{$prefix}-add):not(.#{$prefix}-danger):not(.#{$prefix}-dangerous):not(.#{$prefix}-link):not(.#{$antPrefix}-dropdown-trigger)';
$ghostClass: '.#{$prefix}-background-ghost';

%disabledStyle {
  background-color: $bodyBackground;
  color: $disabledColor;
  &:hover {
    background-color: $bodyBackground;
    color: $disabledColor;
  }
}

%primaryButton {
  &:not([disabled]) {
    &:not(#{$ghostClass}) {
      background-color: $primaryColor;
      &:hover {
        color: $primaryColor;
        background-color: $clientColor;
        text-shadow: none;
      }
    }
    border-color: $primaryColor;
    &:hover {
      color: $primaryColor;
      background-color: transparent;
    }
    &#{$ghostClass} {
      color: $primaryColor;
      &:hover {
        color: $primaryHover;
      }
    }
  }
  &:disabled {
    border-color: $borderColor;
    &:hover {
      border-color: $disabledColor;
    }
    &:not(#{$ghostClass}) {
      @extend %disabledStyle;
    }
  }
}

%addButton {
  &:not([disabled]) {
    &:not(#{$ghostClass}) {
      color: #fff;
      text-transform: uppercase;
      font-size: 13px;
      background-color: $primaryColor;
      border-color: $primaryColor;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
      &:hover {
        color: $clientColor;
        background-color: $secondaryColor;
        border-color: $secondaryColor;
        text-shadow: none;
      }
    }
    border-color: $addColor;
    &:hover {
      color: $addColor;
      background-color: transparent;
    }
    &#{$ghostClass} {
      color: $addColor;
      &:hover {
        color: $primaryHover;
      }
    }
  }
  &:disabled {
    border-color: $borderColor;
    &:hover {
      border-color: $disabledColor;
    }
    &:not(#{$ghostClass}) {
      @extend %disabledStyle;
    }
  }
}

%defaultButton {
  &:not([disabled]) {
    background-color: $primaryColor;
    color: $clientColor;
    border-color: $primaryColor;
    &:hover {
      color: $clientColor;
      border-color: $primaryColor;
    }
  }
  &:disabled {
    color: $disabledColor;
    background-color: $bodyBackground;
    border-color: $borderColor;
  }
}

%linkButton {
  &:not(disabled) 
  &:not(.#{$prefix}-dangerous) {
    color: $primaryColor;
    border: none;
    &:hover {
      color: $primaryColor;
      opacity: 0.8;
    }
    &#{$ghostClass}:hover {
      color: $itemHoverColor;
    }
  }
  &:disabled {
    color: $disabledColor;
    &:hover {
      color: $disabledColor;
    }
  }
}

%dangerButton {
  &:not(disabled) {
    background-color: $errorColor;
    color: $clientColor;
    &:hover,
    &:focus,
    &:active {
      background-color: $clientColor;
      color: $errorColor;
      border: 1px solid $errorColor;
      opacity: 1;
    }
    &.ant-btn-link:hover,
    &.ant-btn-link:focus,
    &.ant-btn-link:active {
      color: $textColor;
    }
  }
  &:disabled {
    @extend %disabledStyle;
    border-color: $borderColor;
    &:hover {
      border-color: $borderColor;
    }
  }
  &.#{$prefix}-link:disabled {
    color: $disabledColor;
    &:hover {
      color: $disabledColor;
    }
  }
  &.#{$prefix}-background-ghost {
    border-color: $secondaryColor;
    color: $secondaryColor;
    &:hover,
    &:focus,
    &:active {
      border-color: $secondaryColor;
      color: $secondaryColor;
    }
  }
  &.#{$prefix}-link {
    color: $textColor;
    background: transparent;
    text-transform: uppercase;
    font-size: 13px;
    &:hover {
      border: 1px solid $secondaryBorderColor;
    }
  }
}

%ghostButton {
  &:not(disabled) {
    &.#{$prefix}-primary:hover,
    &.#{$prefix}-primary:focus {
      border-color: $primaryColor;
    }
  }
  &:disabled {
    color: $disabledColor;
    &:hover {
      color: $disabledColor;
    }
  }
}

.#{$prefix} {
  &:not(.#{$prefix}-circle) {border-radius: 3px;}
  &::before {
    background: $bodyBackground;
  }
  & > svg {
    transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  & > svg + span,
  & > span + svg {
    margin-left: 8px;
  }
  // #{$notClasses} {
  //   @extend %defaultButton;
  // }
  &-lg {
    font-size: 12px;
  }
  &-sm {
    line-height: 100%;
  }
  &.light-btn {
    border: 1px solid $primaryColor; 
    border-radius: 10px;
    padding: 3px 10px;
    height: fit-content;
    &:hover {
      box-shadow: 2px 2px 0 $primaryColor;
  }
}
}

.#{$prefix}-dashed {
  border: 1px dashed $textColorSecondary;
  span {
    color: $primaryColor;
    text-transform: uppercase;
    font-size: 13px;
  }
}
.#{$prefix}-dashed:hover {
  border: 1px dashed $textColor;
  span {
    font-weight: 700;
  }
}

.#{$prefix}-default {
  background: $clientColor;
  color: $textColor;
  border: 1px solid $textColorSecondary;
}
.#{$prefix}-default:hover {
  color: $primaryColor;
  border: 1px solid $primaryColor;
}
.#{$prefix}-link {
  color:$primaryColor;
}
.#{$prefix}-link:hover {
  color:$textColor;
}

.#{$prefix}-primary {
  @extend %primaryButton;
}

.#{$prefix}-default:not(.#{$antPrefix}-input-search-button) 
  &:not(.#{$prefix}-dangerous) {
    @extend %defaultButton;
}

.#{$prefix}-add {
  @extend %addButton;
  background: $addColor;
  border-color: $addColor;

  &:hover {
    background: #fff;
    border-color: $addColor;
    color: $addColor;
  }
}

.#{$prefix}-link {
  @extend %linkButton;
}

.#{$prefix}-dangerous {
  @extend %dangerButton;
}

.#{$prefix}-background-ghost {
  @extend %ghostButton;
}

.orange-button {
  background-color: rgba(244, 141, 65, 1);
  color: $clientColor;
  border-color: $clientColor;
  padding: 8px 0 8px 0;
  height: auto;
  &:hover {
    background-color: $clientColor;
    color: rgba(244, 141, 65, 1);
    border-color: rgba(244, 141, 65, 1);
  }
}