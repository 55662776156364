.material-test-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 160px;
    color: $textColorSecondary;
    width: 70%;
    margin-left: 50px;
    .ant-row {
        align-items: center;
        margin-bottom: 15px;
        .anticon {
            color: $primaryColor;
            font-size: 34px;
            margin-right: 10px;
        }
        .img {
            margin-right: 15px;
        }
    }
}