@import 'variables';

$prefix: $antPrefix + -tabs;

.#{$prefix} {
  color: $textColor;
  > .#{$prefix}-nav::before,
  &-bar {
    border-bottom-color: $bodyBackground;
  }
  &-content {
    color: $textColor;
  }

  &-nav .#{$prefix}-tab {
    &-active,
    &:hover {
      color: $primaryColor;
    }
    &-disabled,
    &-disabled:hover {
      color: $disabledColor;
    }
  }

  &-ink-bar {
    background-color: $primaryColor;
  }
}

.#{$prefix}-top {
  padding: 8px 16px 16px 24px;
  background: $clientColor;
  border: 1px solid $secondaryBorderColor;
  border-radius: 2px;
  box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.05);

  .#{$prefix}-nav {
    .#{$prefix}-nav-wrap {
      .#{$prefix}-nav-list {

        .#{$prefix}-tab-active {
          .#{$prefix}-tab-btn {
            color: $primaryColor;
            text-transform: uppercase;
            letter-spacing: 1px;
            font-size: 13px;
          }
        }

        .#{$prefix}-tab {
          .#{$prefix}-tab-btn {
            text-transform: uppercase;
            font-size: 13px;
            letter-spacing: 1px;
          }
        }
      }
    }
  }

  .#{$prefix}-content-holder {
    .#{$prefix}-content-top{
      .#{$prefix}-tabpane.#{$prefix}-tabpane-active {
        div.ant-row {
          max-width: 100%;
          .ant-layout-content {
            padding: 0 !important;
          }
        }      
      }
    }
  }
}