@import 'variables';

$prefix: $antPrefix + -radio;

.#{$prefix} {
  &-wrapper,
  & {
    color: $textColor;
  }
  &-wrapper:hover .#{$prefix}-inner,
  &:hover .#{$prefix}-inner,
  &-input:focus + .#{$prefix}-inner {
    border-color: $primaryColor;
  }
  &-inner {
    background-color: $bodyBackground;
    border-color: $borderColor;
  }
  &-checked {
    &:after {
      border-color: $primaryColor;
    }
    
    .#{$prefix}-inner {
      border-color: $primaryColor;
      background-color: $bodyBackground;
      &::after {
        background-color: $primaryColor;
      }
    }
  }
  &-indeterminate .#{$prefix}-inner {
    background-color: $bodyBackground;
    border-color: $borderColor;
    &::after {
      background-color: $primaryColor;
    }
  }
  &-disabled .#{$prefix}-inner {
    background-color: $bodyBackground;
  }
}

.#{$prefix}-button-wrapper:not(.#{$prefix}-button-wrapper-disabled) {
  background-color: $clientColor;
  border-color: $clientColor;
  color: $textColor;
  box-shadow: none;
  &::before {
    background-color: transparent;
  }
}


.#{$prefix}-button-wrapper-checked:not(.#{$prefix}-button-wrapper-disabled) {
  background-color: $primaryColor;
  color: $clientColor;
  border-color: $primaryColor;
  border-radius: 5px;
  font-weight: 500;
  box-shadow: none;
  &::before {
    background-color: transparent;
  }
}
