@import 'variables';

$prefix: $antPrefix + -table;

.#{$prefix}-wrapper.weekly-calendar {
    .#{$antPrefix}-spin-nested-loading {
        .#{$antPrefix}-spin-container {
            .#{$prefix}.#{$prefix}-bordered.#{$prefix}-fixed-header {
                .#{$prefix}-container {
                    border-color: transparent;
                    .#{$prefix}-header {
                        table {
                            display: block;
                            border-top: none;
                            .#{$prefix}-thead {
                                width: 100%;
                                display: block;
                                tr {
                                    width: 100%;
                                    display: flex;
                                    th.#{$prefix}-cell:nth-child(1) {
                                        flex: 1 1 5%;
                                    }
                                    th.#{$prefix}-cell:not(:nth-child(1)) {
                                        flex: 1 1 10%;
                                    }
                                    .#{$prefix}-cell {
                                        display: inline-block;
                                        border-radius: 8px;
                                        padding: 5px 10px;
                                        text-align: center;
                                        font-size: 12px;
                                    }
                                    .#{$prefix}-cell.#{$prefix}-cell-scrollbar {
                                        background: transparent;
                                        border-bottom: 1px solid transparent;
                                        border-right: 1px solid transparent;
                                        display: none !important;
                                    }
                                }
                            }
                        }
                    }
                    .#{$prefix}-body {
                        overflow-y: hidden !important;
                        table {
                            display: block;
                            .#{$prefix}-tbody {
                                width: 100%;
                                display: block;
                                tr.#{$prefix}-row.#{$prefix}-row-level-0 {
                                    height: 70px;
                                    display: flex;
                                    min-width: 100%;
                                    td.#{$prefix}-cell:nth-child(1) {
                                        display: inline-block;
                                        flex: 1 1 5%;
                                    }
                                    td.#{$prefix}-cell:not(:nth-child(1)) {
                                        display: inline-block;
                                        flex: 1 1 10%;
                                        overflow: auto;
                                        div {
                                            height: auto !important;
                                            p {
                                                display: block;
                                                height: fit-content;
                                                background: $clientColor ;
                                                border: 1px solid $primaryColor ;
                                                border-radius: 10px;
                                                padding: 1px 5px;
                                                transition: all 0.3s;
                                                margin: 3px 10px 0 10px;
                                                .consultation-event-calendar {
                                                    div.#{$antPrefix}-row {
                                                        justify-content: space-between;
                                                        align-items: center;
                                                        .#{$antPrefix}-col:nth-child(1) {
                                                            font-size: 11px;
                                                            color: $textColorSecondary;
                                                            border: none;
                                                            white-space: normal;
                                                        }
                                                        .#{$antPrefix}-col:nth-child(2) {
                                                            color: $textColor;
                                                            font-weight: 600;
                                                            font-size: 12px;
                                                            border: none;
                                                        }
                                                    }
                                                }
                                                .unavailability-event-calendar {
                                                    width: 100%;
                                                    background: $borderColor;
                                                    min-height: 100%;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}