@import 'variables';

$prefix: $antPrefix + -collapse;

.#{$prefix} {
  color: $textColor !important;
  line-height: 35px;
  &-borderless {
    background-color: $componentBackground !important;
    & > .#{$prefix}-item > .#{$prefix}-content {
      background: transparent !important;
    }
  }

  & > .#{$prefix}-item > .#{$prefix}-header {
    color: $textColor !important;
  }

  &-content {
    color: $textColor !important;
    background: $contrastBackground !important;
  }
}

.#{$prefix}-icon-position-start {
  background: transparent;
  border-color: transparent;
  .#{$prefix}-item {
    margin-bottom: 16px;
    border-bottom: transparent;
    .#{$prefix}-header {
      background: $clientColor;
      border: 1px solid $secondaryBorderColor;
      border-radius: 6px;
      padding: 8px 16px;
      .#{$prefix}-expand-icon {
        .anticon {
          svg {
          color: $primaryColor;
          }
        }
      }
      .#{$prefix}-header-text {
        text-transform: uppercase;
        font-size: 13px;
        letter-spacing: 1px;
        font-weight: 700;
      }
    }
    .#{$prefix}-content {
      border-top: none;
      .#{$prefix}-content-box {
        border: 1px solid $secondaryBorderColor;
        border-radius: 4px;
        background: $clientColor;
        .ant-btn-default {
          margin: 0 8px 8px 0;
          padding: 0 12px;
          border: 1px dashed $primaryColor ;
          span {
            font-size: 13px;
          }
        }
        .ant-btn-default:hover, 
        .ant-btn-default:active, 
        .ant-btn-default:focus {
          background: $primaryColor;
          color: $clientColor;
        }
      }
    }
  }
}


