@import 'variables';

$prefix: $antPrefix + -upload;

.#{$prefix}, .#{$antPrefix}-form-item  {
  color: $textColor;

  .#{$prefix}.#{$prefix}-drag {
    background: $componentBackground;
  }
}

.#{$prefix}-drag {
  background: none;
  border: none;
  height: auto;
  p.#{$prefix}-drag-icon {
    .anticon {
      font-size: 45px;
      color: $primaryColor;
    }
  }
  p.#{$prefix}-text {
    color: $textColorSecondary;
  }
  .#{$prefix}-btn {
    background: transparent;
    border: 1px dashed $primaryColor;
    height: 80%;
    .#{$prefix}-drag-container {
      .#{$prefix}-drag-icon {
        margin-bottom: none;
        .anticon {
          color: $primaryColor !important;
        }
      }
    }
  }
}
.#{$prefix}-drag {
  .#{$prefix}-btn:hover {
    border: 1px dashed $textColor !important;
  }
}

