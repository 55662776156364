@import 'variables';

$prefix: $antPrefix + -form;

.#{$prefix} {
  &-item-label > label {
    color: $textColor;
  }
  &-item-control:not(.has-error) {
    .#{$prefix}-explain {
      color: $disabledColor;
    }
  }
  &-item {
    margin-bottom: 10px;
  }
}

.#{$prefix}-item {
  .#{$prefix}-item-row {
    flex-direction: row;

    .#{$prefix}-item-label {
      text-align: right;
      >label {
        color: $primaryColor;
        font-size: 13px;
        text-transform: uppercase;
      }
    }
  }
}


