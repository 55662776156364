@import 'variables';

$prefix: $antPrefix + -descriptions;

.#{$prefix} {
  background: $clientColor;

  &-bordered {
    .#{$prefix} {
      &-view,
      &-row,
      &-item-label,
      &-item-content {
        border-color: $contrastBackground;
      }
      &-item-label {
        background: $contrastBackground;
      }
    }
  }
  &-title,
  &-item-content {
    color: $primaryColor;
    font-weight: 600;
  }
  &-item-label{
    color: $primaryColor;
    text-transform: uppercase;
    font-size: 13px;
  }
  .#{$prefix}-item-container {
    flex-direction: column;
  }
  .#{$prefix}-view {
    padding-left: 20px;;
  }
  .#{$prefix}-header {
    margin-bottom: 24px;
  }
}
