@import 'variables';

$prefix: $antPrefix + -dropdown;
$btnPrefix: $antPrefix + -btn;

%dropdownTrigger {
  color: $clientColor;
  border-color: $borderColor;
  line-height: inherit;
  &:hover,
  &:focus,
  &:focus-within {
    border-color: $primaryColor;
    color: $borderColor;
  }
  & > span + span {
    margin-left: 10px;
  }

  &.#{$prefix}-open {
    .#{$prefix}-arrow {
      transform: rotate(180deg);
    }
  }

  &.#{$btnPrefix} {
    &-primary:not([disabled]) {
      border-color: $primaryColor;
    }
    &[disabled] {
      color: $disabledColor;
      background: $componentBackground;
      border-color: $borderColor;
      &:hover {
        background: $componentBackground;
        border-color: $borderColor;
      }
    }
  }
}

.#{$prefix} {
  &-open {
    color: $primaryColor;
  }
  color: $textColor;
  &-menu {
    background-color: $componentBackground;
    border: 1px solid $contrastBackground;
    &-item {
      color: $textColor;
      font-size: 12px;
      &:not(.no-hover):hover {
        background-color: $itemHoverBackground;
      }
      &.no-hover:hover {
        background-color: transparent;
      }
      &.no-padding {
        padding: 0;
      }
    }
    &-item-selected,
    &-submenu-title-selected,
    &-item-selected > a,
    &-submenu-title-selected > a {
      background-color: $itemHoverBackground;
    }
  }
  &-trigger {
    @extend %dropdownTrigger;
  }
  &-arrow {
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
}
