@import 'variables';

$prefix: $antPrefix + -card;

.#{$prefix} {
  background: $componentBackground;
  color: $textColor;
  margin-bottom: 24px;
  &-head,
  &-meta-title,
  &-meta-description {
    color: $textColor;
  }
  &-head {
    border-bottom: transparent;
    color: $primaryColor;

    .#{$prefix}-head-title {
      font-weight: 600;
      letter-spacing: 2px;
      padding-top: 24px;
    }
  }
  &-bordered {
    border-color: $secondaryBorderColor;
    box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.05);
  }
}

.waiting-room-card {
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  justify-content: center;
  height: 40%;
  width: 50%;
  background-color: $bodyBackground;
  border: 1px solid transparent;
  border-radius: 10px;
  .ant-row {
    color: $primaryColor;
    font-size: 20px;
    letter-spacing: 1px;
    margin-bottom: 20px;
  }
}