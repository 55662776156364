@import 'variables';

$prefix: $antPrefix + -page-header;

.#{$prefix} {
  background: $textColor;
  border-radius: 0%;

  &-heading-title,
  &-back-button,
  & > .#{$antPrefix}-breadcrumb a:not(:hover),
  & > .#{$antPrefix}-breadcrumb > span:last-child a {
    color: $clientColor;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 1px;

  }
  & > .#{$antPrefix}-breadcrumb > span:last-child a {
    cursor: auto;
  }

  .#{$antPrefix}-breadcrumb-separator {
    color: $textColorSecondary;
  }
}