@import 'variables';

$prefix: $antPrefix + -list;

.#{$prefix} {
  color: $textColor;
  &-item-meta {
    &-title,
    &-description {
      color: $textColor;
    }
  }
  &-item {
    border-color: $borderColor !important;
  }
}

.custom-document-list {
  .custom-list-item {
    justify-content: space-between;
    padding: 8px 8px;
    background: $bodyBackground;
    margin: 0 16px 12px 0;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.05);
    height: 40px;
    .document-meta {
      width: 85%;
      justify-content: space-between;
      margin-left: 8px;
      align-items: center;
      display: flex;
      .custom-list-label {
        font-size: 13px;
        .anticon-close-circle {
          color: $errorColor;
          padding-right: 8px;
          svg {
            width: 17px;
            height: 17px;
          }
        }
        .anticon-check-circle {
          color: $addColor;
          padding-right: 8px;
          svg {
            width: 17px;
            height: 17px;
          }
        }
      }
      .customfile {
        padding: 1px;
      }
    }
    .custom-list-action {
      height: fit-content;
      display: flex;
      align-items: center;
      .ant-btn-link {
        padding: 0 8px;
        color: $primaryColor;
        display: flex;
        height: fit-content;
      }
      .anticon-delete {
        padding: 0 8px;
        color: $errorColor;
        svg {
          width: 18px;
          height: 18px;
        }
      }
    }
  }
}
