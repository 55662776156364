@import 'variables';

.unavailability-range {
    >.#{$antPrefix}-col:nth-child(1) {
        display: flex;
        justify-content: end;
    }  
    >.#{$antPrefix}-col:nth-child(2) {
        .#{$antPrefix}-radio-group.ant-radio-group-outline {
            display: flex;
            justify-content: end;
            flex-direction: column;
            >.#{$antPrefix}-radio-wrapper:nth-child(2) {
                div.#{$antPrefix}-row {
                    >div.#{$antPrefix}-row:nth-child(2){
                        margin: 5px 0 0 10px;
                        color: $primaryColor;
                    }
                }
            }
            >div.#{$antPrefix}-form-item.#{$antPrefix}-form-item-has-success,.#{$antPrefix}-form-item {
                .#{$antPrefix}-radio-wrapper.#{$antPrefix}-radio-wrapper-in-form-item {
                    color: $primaryColor;
                }
            }
        }
    }  
}

.unavailability-frequency {
    justify-content: center; 
}