@import 'variables';

$prefix: $antPrefix + -layout;

.#{$prefix} {
  background: $bodyBackground;
  &-header {
    background: $clientColor;
  }

  &-content {
    background: $bodyBackground; 
    border-radius: 0%;
  }
}
