@import 'variables';

$prefix: $antPrefix + -tag;

.#{$prefix} {
  background: transparent;
  border-radius: 4px;
  a,
  a:hover {
    color: $textColorSecondary;
  }
}
