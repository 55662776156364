@import 'variables';

$prefix: $antPrefix + -picker;

.custom-calendar-cell {
    list-style: none;
    user-select: none;
    width: 100%;
    height: 100%;
}

.#{$prefix}-calendar-full.saturday-calendar {
    background: transparent;

    .#{$prefix}-panel {
        background: transparent;

        .#{$prefix}-content {
            border-collapse: separate;
            border-spacing: 3px;

            thead {
                >tr {
                    th:nth-child(7) {
                        display: none;
                    }

                    th {
                        text-align: center;
                        font-size: 12px;
                        text-transform: uppercase;
                        background: $primaryColor;
                        text-align: center;
                        border-radius: 8px;
                        color: $clientColor;
                        padding: 5px 20px !important;
                        margin: 0 2px;
                    }
                }
            }

            tbody {
                tr {
                    td:nth-child(7) {
                        display: none;
                    }

                    td.#{$prefix}-cell-in-view, td.#{$prefix}-cell-selected {
                        .#{$prefix}-calendar-date-value {
                            background: $clientColor;
                            color: $textColor;
                            border: 1px solid $primaryColor !important;
                        }
                    }

                    td.#{$prefix}-cell-today {
                        .#{$prefix}-calendar-date-value {
                            background: $primaryColor !important;
                            color: $clientColor !important;
                        }
                    }

                    td.#{$prefix}-cell {
                        .#{$prefix}-calendar-date-value {
                            border: 1px solid ;
                        }
                    }

                    td.#{$prefix}-cell-in-view, 
                    td.#{$prefix}-cell-today, 
                    td.#{$prefix}-cell-selected, 
                    td.#{$prefix}-cell-end,
                    td.#{$prefix}-cell {
                        .#{$prefix}-cell-inner.#{$prefix}-calendar-date {
                            height: 130px;
                            margin: 0 1px;
                            width: auto;
                            height: auto;
                            background: $clientColor;
                            padding: 8px;
                            margin: 0 1px;
                            border: 1px solid rgba(0, 0, 0, 0.06);
                            border-radius: 5px;
                            &:hover {
                                border: 1px solid $primaryColor;
                            }

                            .#{$prefix}-calendar-date-value {
                                position: absolute;
                                right: 8px;
                                width: 15%;
                                text-align: center;
                                font-size: 12px;
                                border-radius: 10px;
                                z-index: 4;
                            }

                            div.#{$prefix}-calendar-date-content {
                                height: 110px;
                                div {
                                    height: auto;
                                    background: transparent !important;
                                    .consultations-counter {
                                        width: fit-content;
                                        align-items: center;
                                        gap: 1px;
                                        padding: 2px 6px;
                                        border: 1px solid $textColorSecondary;
                                        border-radius: 10px;
                                        color: $textColor;
                                        font-size: 12px;
                                        .anticon {
                                            color: $textColorSecondary;
                                        }
                                    }
                                    li>a>.#{$antPrefix}-tag {
                                        display: block;
                                        height: fit-content;
                                        background: $clientColor ;
                                        border: 1px solid $primaryColor ;
                                        border-radius: 10px;
                                        padding: 1px 5px;
                                        transition: all 0.3s;
                                        margin: 3px 10px 2px 10px;
                                    }
                                    .#{$antPrefix}-col {
                                        .#{$antPrefix}-row:nth-child(1) {
                                            li>a>.#{$antPrefix}-tag {
                                                display: block;
                                                height: fit-content;
                                                background: $clientColor ;
                                                border: 1px solid $primaryColor ;
                                                border-radius: 10px;
                                                padding: 1px 5px;
                                                transition: all 0.3s;
                                                margin: 10px 10px 2px 10px;
                                                .#{$antPrefix}-row {
                                                    justify-content: space-between;
                                                    align-items: center;
                                                    .#{$antPrefix}-col:nth-child(1) {
                                                        font-size: 11px;
                                                        color: $textColor;
                                                        border: none;
                                                        white-space: normal;
                                                    }
                                                    .#{$antPrefix}-col:nth-child(2) {
                                                        color: $textColor;
                                                        font-weight: 600;
                                                        font-size: 12px;
                                                        border: none;
                                                    }
                                                } 
                                            }
                                        }
                                        .#{$antPrefix}-row:nth-child(2) {
                                            justify-content: space-between;
                                            max-width: 100%;
                                            margin: 0 10px;

                                            .#{$antPrefix}-col:nth-child(1) {
                                                color: $textColor;
                                                font-weight: 600;
                                                font-size: 12px;
                                                li>a>.#{$antPrefix}-tag {
                                                    margin: 3px 0 0 0; 
                                                }
                                            }
                                            .#{$antPrefix}-col:nth-child(2) {
                                                .#{$antPrefix}-btn.light-btn {
                                                    margin-top: 2px;
                                                    padding: 1px 10px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            li {
                                a {
                                    .#{$antPrefix}-tag {
                                        display: block;
                                        height: fit-content;
                                        background: $clientColor ;
                                        border: 1px solid $primaryColor ;
                                        border-radius: 10px;
                                        padding: 1px 5px;
                                        transition: all 0.3s;
                                        margin: 3px 10px;
                                        .#{$antPrefix}-row {
                                            justify-content: space-between;
                                            align-items: center;
                                            .#{$antPrefix}-col:nth-child(1) {
                                                font-size: 11px;
                                                color: $textColor;
                                                border: none;
                                                white-space: normal;
                                            }
                                            .#{$antPrefix}-col:nth-child(2) {
                                                color: $textColor;
                                                font-weight: 600;
                                                font-size: 12px;
                                                border: none;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
